<template>
  <!-- 培训协议 -->
  <div class="trainingAgreement">
    <van-nav-bar title="培训协议"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight" />
    <div class="overflow_div">
      <div class="footer">
        <!-- <p class="title">标准固化模型</p> -->
        <p class="content">
          欢迎使用苏宁易购客户端软件，苏宁易购APP由江苏
          苏宁易购电子商务有限公司开发、拥有、运营的电子商
          务应用。
        </p>
        <p class="content">
          客户端使用完全免费，在使用过程中会产生流量费，
          流量费标准请咨询当地运营商;当您成功注册成为苏宁易
          购会员后，您可凭苏宁易购会员账号登录苏宁集团旗下
          的多个交易平台，且无需另行注册账号。详细会员章程，
          您可参考:《苏宁易购会员章程》;
        </p>
        <p class="content">
          为了保障客户端的正常运行以及能够向您提供更全面 的功能服务，我们将可能向系统申请以下权限:启用SD卡 读写数据功能;使用手机拍照功能;申请手机设备信息，包 括IMEl、(IMSI) 、MAC等后台行为，用于数据统计，通 知消息等;向系统申请语音功能，方便您语音搜索您想浏 览的选购商品;向系统申请图片权限，用于评论商品发表 图片，设置头像等;申请访问通讯录，用于虚拟充值服务 等;向系统申请位置服务，为您在所在城市浏览选购可售 商品;获取读取应用列表权限，以保证您的交易安全;读取 剪切板，用于粘贴信息、口令等。以上权限都是系统公 开权限。
        </p>
        <p class="content">
          如您想开启或关闭相关权限,您可在手机设置--应用程 序管理--苏宁易购--权限管理中更改状态(各厂商机型设置 路径可能存在不一致，您可参考厂商设置说明)
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  methods: {
    onClickLeft () {
      this.$router.push('/home')
    },
    onClickRight () { }
  }
}
</script>

<style lang="less" scoped>
.trainingAgreement {
  height: 100vh;
  overflow: hidden;
  background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    overflow: auto;
    height: calc(100% - 100px);
    .footer {
      .content {
        padding: 0 30px;
        text-indent: 2em;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        line-height: 48px;
        color: #333333;
      }
    }
  }
}
</style>